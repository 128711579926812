import Project from "../project/Project";
import imglanguages from "../../images/languages-min.jpg";
import imgtodofront from "../../images/todofront-min.jpg";
import imgtodoback from "../../images/todoback-min.jpg";
import maltayoga from "../../images/maltayoga.jpg";
import Bubble from "../animation/Bubble";
import { useRef } from "react";
import useOnScreen from "../util/useOnScreen";
import scrollIntoView from "scroll-into-view-if-needed";
function Projects() {
  const refBubble = useRef(null);
  const refProjects = useRef(null);
  const isVisibleBubble = useOnScreen(refBubble, true);
  const isVisibleProjects = useOnScreen(refProjects, true);

  const projects = [
    {
      title: "Laravel Languages",
      description:
        "<p>Laravel Languages provides a convenient way to manage the language files in the filesystem. The package does not only enable to import and export the translations data to and from a database. It has also an isolated UI to manage languages, translators and translations.</p>" +
        "<br><p>It's very simple, the developer adds the translation keys to the project and the rest is managed by the translators and administrators in Laravel Languages.</p>",
      img: imglanguages,
      website: "https://www.laravel-languages.com",
      github: "https://github.com/riomigal/languages",
    },
    {
      title: "To-Do app Frontend",
      description:
        "<p>Creating a To-Do app, especially as a first attempt, can be a great learning experience for coding and software development. I have created a front end in ReactJS and back end in Laravel. This is a simple example and might not cover all features of a full-fledged To-Do app. Visit the website to try out the demo.</p>",
      img: imgtodofront,
      website: "https://todo.migale.eu",
      github: "https://github.com/riomigal/todofront",
    },
    {
      title: "To-Do app Backend",
      description:
        "<p>This is the backend of the To-Do app. I have created a front end in ReactJS and back end in Laravel. The app is simple and does not cover all features of a full-fledged To-Do app. Visit the website to try out the demo.</p>",
      img: imgtodoback,
      website: "https://todo.migale.eu",
      github: "https://github.com/riomigal/toDoBackend",
    },
    {
      title: "Malta Yoga",
      description:
        "<p>Malta Yoga's website was developed using WordPress, incorporating themes and plugins to minimize extensive web development efforts.</p>",
      img: maltayoga,
      website: "https://www.maltayoga.com",
    },
  ];
  return (
    <div id="projects" className="hero min-h-screen w-100 p-3">
      <div className="grid justify-items-center">
        <h2 className="text-5xl font-bold mb-6">Projects</h2>
        <div ref={refBubble} className={"z-50 absolute mt-[200px]"}>
          {isVisibleBubble && (
            <Bubble
              id="projects"
              text=" Click on one of the projects to delve deeper into its details."
              showDelay={1000}
              closeDelay={3000}
            />
          )}
        </div>

        <div ref={refProjects}>
          {isVisibleProjects && (
              <div className={'text-center'}>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 container">
                  {projects.map((project, index) => (
                    <div className={"animate-[spin_500ms]"} key={"project" + index}>
                      <Project {...project} />
                    </div>
                  ))}
                </div>
                <div className={"animate-jump-in animate-delay-[2s] animate-once"}>
                  <button
                      className={"btn btn-lg btn-primary animate-pulse mt-6"}
                      onClick={() => {
                        scrollIntoView(document.getElementById("about"), {
                          scrollMode: "if-needed",
                          block: "nearest",
                          inline: "nearest",
                          behavior: "smooth",
                        });
                      }}
                  >
                    About me
                  </button>
                </div>
              </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Projects;
