import TypeWriter from "typewriter-effect";
import { useRef, useState } from "react";
import useOnScreen from "../util/useOnScreen";
import Bubble from "../animation/Bubble";

function About() {
  const refAbout = useRef(null);
  const showAbout = useOnScreen(refAbout, true);
  const [showGreeting, setShowGreeting] = useState(false);
  return (
    <div id="about" className="hero min-h-screen">
      <div ref={refAbout} className="hero-content text-center">
        {showAbout && (
          <div className="max-w-[1200px]">
            <div className="py-6">
              <TypeWriter
                onInit={(typewriter) => {
                  typewriter
                    .changeDelay(20)
                    .typeString(
                      "<p class='text-xl font-bold'>Hmm, just a moment, let me ponder...</p>",
                    )
                    .pauseFor(5000)
                    .changeDelay(50)
                    .typeString(
                      "<p>...so you know I love web development. But it wasn't always like that. </p><br/>",
                    )
                    .pauseFor(1000)
                    .typeString(
                      "<p>I began delving into programming in my late twenties, and initially, it involved a great deal of struggle and hardship.<br/>" +
                        "I went to school, took courses and obtained as well a PHP certification. <br/> Did it help? A little...</p>",
                    )
                    .pauseFor(1000)
                    .typeString(
                      "What helped me the most was my increasing involvement with frameworks such as <b>Laravel, ReactJS</b> and programming languages as <b>PHP and JavaScript</b>. <br><br> I began by following video tutorials and" +
                        " then progressed to independently crafting small applications, relying more on documentation and sometimes Stackoverflow or Laracasts. :) <br/> " +
                        "Eventually all this hard work bore fruit, leading me to secure my first job." +
                        "</p>",
                    )
                    .pauseFor(1500)
                    .typeString(
                      "<br><p>Do you know that I have also other hobbies? Like:</p><br/>",
                    )
                    .pauseFor(1000)
                    .typeString(
                      "<ul class='font-bold'><li>My family</li><li>Football</li><li>Weightlifting</li><li>Music</li><li>Hiking</li><li>Cooking and eating</li></ul>",
                    )
                    .typeString(
                      '<br><p>Should you have any questions, feel free to send me an email at: <a href="mailto:hi@migale.eu">hi@migale.eu</a></p>',
                    )
                    .callFunction((node) => {
                      node.elements.cursor.remove();
                      setShowGreeting(true);
                    })
                    .start();
                }}
              />
              {showGreeting && (
                <Bubble
                  text={"See you! :)"}
                  id={"greeting"}
                  dontClose={true}
                  showMultipleTimes={true}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default About;
