import "./App.css";
import { useState } from "react";
import Navigation from "./components/layout/Navigation";
import Home from "./components/pages/Home";
import Projects from "./components/pages/Projects";
import About from "./components/pages/About";

function App() {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "standard",
  );

  return (
    <div id={"theme"} data-theme={theme} className={"text-lg"}>
      <Navigation
        theme={theme}
        setTheme={(theme) => {
          setTheme(theme);
          localStorage.setItem("theme", theme);
        }}
      />
      <Home />
      <Projects />
      <About />
    </div>
  );
}

export default App;
