import "./Bubble.css";
import { useState } from "react";
import TypeWriter from "typewriter-effect";
import BubbleMan from "../../images/bubble-man.png";

function Bubble({
  id,
  text,
  dontClose,
  showDelay,
  closeDelay,
  showMultipleTimes,
}) {
  const [visible, setVisible] = useState(false);

  return (
    <div className={"m-0 p-3 w-full z-50"}>
      {
        <div
          className={
            "animate-jump-in animate-once flex " + (visible ? "" : "hidden")
          }
        >
          <img src={BubbleMan} alt={"Bubble Man"} className={" w-[100px]"} />
          <div className={"absolute chat chat-end ml-10 mt-11"}>
            <div className=" chat-bubble chat-bubble-primary w-[200px] rotate-180">
              <div className="type rotate-180">
                <TypeWriter
                  onInit={(typewriter) => {
                    setTimeout(() => {
                      if (showMultipleTimes) {
                        setVisible(true);
                      }
                      if (!localStorage.getItem("bubble")) {
                        localStorage.setItem("bubble", JSON.stringify({}));
                      }
                      const bubbleStorage = JSON.parse(
                        localStorage.getItem("bubble"),
                      );

                      if (showMultipleTimes || !(id in bubbleStorage)) {
                        setVisible(true);
                        typewriter
                          .changeDelay(50)
                          .typeString(text)
                          .start()
                          .callFunction((node) => {
                            bubbleStorage[id] = true;
                            localStorage.setItem(
                              "bubble",
                              JSON.stringify(bubbleStorage),
                            );
                            setTimeout(
                              () => setVisible(dontClose ?? false),
                              closeDelay ?? 1000,
                            );
                            node.elements.cursor.remove();
                          });
                      }
                    }, showDelay ?? 0);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Bubble;
