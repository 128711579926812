import "./Navigation.css";
import Select from "../data-input/Select";
import Bubble from "../animation/Bubble";
import CloseButton from "../button/CloseButton";
import scrollIntoView from "scroll-into-view-if-needed";

function Navigation(props) {
  const themesArray = [
    "standard",
    "light",
    "dark",
    // "cupcake",
    // "synthwave",
    // "retro",
    // "cyberpunk",
    // "valentine",
    // "halloween",
    // "garden",
    // "forest",
    // "aqua",
    // "lofi",
    // "pastel",
    // "fantasy",
    // "wireframe",
    // "black",
    // "luxury",
    // "dracula",
    // "cmyk",
    // "autumn",
    // "business",
    // "acid",
    // "lemonade",
    // "night",
    // "coffee",
    // "winter",
  ];

  function getThemes() {
    const themes = {};
    themesArray.forEach((theme) => {
      themes[theme] = theme.charAt(0).toUpperCase() + theme.slice(1);
    });
    return themes;
  }

  function updateTheme(value) {
    props.setTheme(value);
  }

  function scrollMenu(id) {
    const node = document.getElementById(id);
    if (node) {
      scrollIntoView(node, {
        scrollMode: "if-needed",
        block: "nearest",
        inline: "nearest",
        behavior: "smooth",
      });
      document.getElementById("menu-drawer").click();
    }
  }

  return (
    <div>
      <div className={"fixed ml-[150px] z-50"}>
        <Bubble
          id="menu_item"
          text="The button on the left will open up the menu for you."
          showDelay={1000}
          closeDelay={4000}
        />
      </div>

      <div className="drawer z-50">
        <input id="menu-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <label
            htmlFor="menu-drawer"
            className="btn btn-primary btn-lg ml-5 mt-5 drawer-button animate-jump-in animate-once"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5"
              />
            </svg>
            MIGALE
          </label>
        </div>
        <div className="drawer-side">
          <label htmlFor="menu-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 h-full bg-base-200 text-lg pt-10">
            <CloseButton
              closeAction={() => document.getElementById("menu-drawer").click()}
            />
            <li className="text-center">
              <span onClick={() => scrollMenu("home")}>Home</span>
            </li>
            <li>
              <span onClick={() => scrollMenu("projects")}>Projects</span>
            </li>
            <li>
              <span onClick={() => scrollMenu("about")}>About me</span>
            </li>
            <li className={"mt-10"}>
              <span>
                <Select
                  label={"Change theme"}
                  values={getThemes()}
                  defaultValue={props.theme}
                  onChange={(event) => {
                    updateTheme(event.target.value);
                  }}
                />
              </span>
            </li>
            <li className={"mt-10"}>
              <span>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                  }}
                >
                  Restart App
                </button>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
