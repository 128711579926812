import { useEffect, useState } from "react";

function useOnScreen(ref, checkOnlyOnce = false) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [keepState, setKeepState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      let state = entry.isIntersecting;
      if (checkOnlyOnce) {
        if (!keepState && state) {
          setKeepState(true);
        }
      }
      setIntersecting(state);
    });

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, checkOnlyOnce, keepState]);

  return keepState ? keepState : isIntersecting;
}

export default useOnScreen;
