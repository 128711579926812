function Select(props) {
  return (
    <div className="form-control w-full max-w-xs">
      {props.label ? (
        <label className="label">
          <span className="label-text">{props.label}</span>
        </label>
      ) : (
        ""
      )}
      <select
        className="select w-full max-w-xs"
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      >
        {Object.entries(props.values).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
