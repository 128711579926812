import TypeWriter from "typewriter-effect";
import { useState } from "react";
import scrollIntoView from "scroll-into-view-if-needed";

function Home() {
  const [showStartButton, setShowStartButton] = useState(false);

  return (
    <div id="home" className="hero min-h-screen">
      <div className="hero-content text-center animate-jump-in animate-delay-300 animate-once">
        <div className="max-w-md ">
          <div className="py-6">
            <TypeWriter
              onInit={(typewriter) => {
                typewriter
                  .changeDelay(50)
                  .typeString(
                    '<h1 class="text-5xl font-bold">Hello there!</h1>',
                  )
                  .pauseFor(4000)
                  .typeString(
                    "<p>I'm Saverio, a dedicated web developer with an insatiable curiosity" +
                      "            for turning concepts into captivating digital experiences. I like...</p>",
                  )
                  .changeDelay(1)
                  .typeString(
                    '<div class="flex flex-wrap gap-3 p-3">' +
                      '<div class="badge badge-lg badge-outline animate-jump-in animate-delay-100">PHP / Laravel / Livewire</div>' +
                      '<div class="badge badge-lg badge-accent badge-outline animate-jump-in animate-delay-300">JavaScript / ReactJS / NodeJS</div>' +
                      '<div class="badge badge-lg h-auto badge-secondary text-wrap badge-outline animate-jump-in animate-delay-500">HTML / CSS / TailwindCSS / Bootstrap</div>' +
                      '<div class="badge badge-lg badge-info badge-outline animate-jump-in animate-delay-500">GIT / Github</div>' +
                      '<div class="badge badge-lg badge-primary badge-outline animate-jump-in animate-delay-700">MySQL</div>' +
                      "</div>",
                  )
                  .start()
                  .callFunction((node) => {
                    node.elements.cursor.remove();
                    setShowStartButton(true);
                  });
              }}
            />
          </div>
          <div className={showStartButton ? "" : "hidden"}>
            <div className={"animate-jump-in animate-delay-[2s] animate-once"}>
              <button
                className={"btn btn-lg btn-primary animate-pulse mt-6"}
                onClick={() => {
                  scrollIntoView(document.getElementById("projects"), {
                    scrollMode: "if-needed",
                    block: "nearest",
                    inline: "nearest",
                    behavior: "smooth",
                  });
                }}
              >
                See my projects
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
